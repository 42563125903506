import { createStore } from "vuex";
import Auth from "./modules/Auth";
import Profile from "./modules/Profile";
import State from "./modules/State";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Profile,
    State
  },
});
