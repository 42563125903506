<template>
  <div>
    <!-- <div v-if="isDesktop">
      <Loader v-if="isPageLoading" />
      <router-view v-else :key="$route.fullPath" />
    </div>
    <div v-else><DesktopOnly /></div> -->
    <Loader v-if="isPageLoading" />
    <router-view v-else :key="$route.fullPath" />
  </div>
</template>

<script>
import Loader from "@/components/Elements/Loader.vue";
// import DesktopOnly from "@/components/Elements/DesktopOnly.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isDesktop: true, // flag to detect if it's desktop
    };
  },
  components: {
    Loader,
    // DesktopOnly,
  },
  mounted() {
    this.checkIsDesktop();
    window.addEventListener("resize", this.checkIsDesktop); // update on resize
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkIsDesktop);
  },
  computed: {
    ...mapGetters(["isPageLoading"]),
  },
  methods: {
    checkIsDesktop() {
      // Check if the screen width is greater than 768px (common mobile breakpoint)
      this.isDesktop = window.innerWidth > 768;
    },
  },
};
</script>

<style>
body,
html {
  background-color: #151e23;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
