import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import TokenService from "@/common/token.service";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  state: {
    user: null,
    token: null,
    isAuthenticated: !!TokenService.getToken("token"),
    maintenanceError: null,
    userSrc: !!TokenService.getToken("usersrc"),
  },
  getters: {
    user(state) {
      return state.user;
    },
    token(state) {
      return {
        token: state.token,
        refreshToken: state.refreshToken,
      };
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    maintenanceError(state) {
      return state.maintenanceError;
    },
  },
  mutations: {
    setAuth(state, data) {
      state.user = data.payload;
      state.token = data.token;
      state.isAuthenticated = true;

      TokenService.saveToken("token", state.token);

    },
    purgeAuth(state) {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;

      TokenService.destroyToken("token");
      TokenService.destroyToken("name");
      TokenService.destroyToken("pricing_plan");
    },
    setMaintenanceError(state, data) {
      state.maintenanceError = data;
    },
  },
  actions: {
    checkAuth({ commit, dispatch, rootGetters }) {
      if (TokenService.getToken("token")) {
        ApiService.setHeader();
        // API Call to get user details without Login
        if (!rootGetters.profile) {
          dispatch("getProfile");
        }
      } else {
        commit("purgeAuth");
      }
    },

    login({ commit }, params) {
      return new Promise((resolve, reject) => {
        ApiService.post(apiResource.login, params)
          .then(({ data }) => {
            commit("setAuth", data.data);
            resolve(data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    validateOTP({ commit }, data) {
      commit("setAuth", data.data.data);
    },
    validateLoginOTP({ commit }, data) {
      commit("setAuth", data.data.data);
    },
    signOut({ commit }) {
      commit("purgeAuth");
    },

    signOutLocal({ commit }) {
      commit("purgeAuth");
    },

    setError({ commit }, payload) {
      commit("setMaintenanceError", payload);
      toast.error(payload, { timeout: false });
    },
  },
};
