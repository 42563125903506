<template>
  <div class="w-2/3 relative left-1/2 transform -translate-x-1/2">
    <lottie-animation
      path="lotties/404.json"
      :loop="true"
      :autoPlay="true"
      :speed="1"
      :height="640"
    />
    <p class="text-3xl text-center">
      Oops! The page you are looking for is not found!
    </p>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: { LottieAnimation },
};
</script>

<style scoped></style>
