export default {
    state: {
        isPageLoading: false,
    },
    getters: {
        isPageLoading(state) {
            return state.isPageLoading;
        },
    },
    mutations: {
        setIsPageLoading(state) {
            state.isPageLoading = true;
        },
        setPageLoadingComplete(state) {
            state.isPageLoading = false;
        },
    },
    actions: {
        addPageLoading({ commit }) {
            commit("setIsPageLoading");
        },
        pageLoadingComplete({ commit }) {
            commit("setPageLoadingComplete");
        },
    },
};
