export const config = {
  url: process.env.VUE_APP_API_URL,
  businessUrl: process.env.VUE_APP_BUSINESS_URL,
  customerUrl: process.env.VUE_APP_CUSTOMER_URL,
  env: process.env.NODE_ENV,
};

export const apiResource = {
  validateCustomer: "/7BUXsewaL2PSnh2jeWQ4CgccY0_F-_AbvafW3TLcf_Q=",
  validateOTP: "/6DQ2cycjBTd2T6LiCOI4DiTiq7v6XBi5peGW81XG998=",
  resendOTP: "/02QipOr9fLXgjyjd7xqL54gtKGEERggJtZf-d-I4Wf4=",
  loginValidateEmail: "xfky9cjKd43BaDjJkrNu-xWpwcouYc_FPzW2NKe5OFw=",
  loginValidateOTP: "Fdhyf97OR6gd_r5mOmVYEy8CSNmpSFpGW9GXYLvB9eY=",
  loginResendOTP: "0aN2p5ZbkWpLiNC88POwMrsBpgHk6PF3Ip7McwO8qKs=",
  profile: "l9uQCVnZVEF4iWWM73ZoGaMomq6E6rWQN4GOt4FNzs4=",
  getPricingDetails: "/7-MYDe93rwAcu4rL2Sr_8xEdS6KXu7ipRqFKuQ-OSbE=",
  checkPricingForUser: "/317VkiHxjBwaUbOASbdbS8bONutt6rsrCkGrKwKQ1xY=",
  continueWithGoogle: "/fNRpjD5ez2pZ1UengQSRq7SRN75UoEsG187YAeRj5Zk=",
  gameJams: {
    getDetails: "/x3GDwA1eDSon273QNJxsfWOv08Rf50U27nR935dvJIc=",
    createRazorpayOrder: "/9NbxERTSNgMed1kxqPJpSEyXVb2qtZ26Rv3Szj0b7ME=",
    confirmPayment: "/N8pAcwbfsZgFsFCCmilUuWGSfmhHU-cNvEjaypu9O5A=",
    saveProgress: "/OAYn5IHOHouA2A9CG-ZxmqGhgJDZ8NFcNectFtG36_c=",
    updateGameJamName: "/w6M9dJ928vRSY01FqqzQeBKvWvxpwH2-qqIlVdPW_r8=",
    uploadLogo: "/FL1ExxLWwEkOVYeF1TYcveoLEA5groQ71zktXedXwoU=",
    removeLogo: "/ObjW1nunH4mrCx9zyDNhEk6YsfeuQtBW_g_ne8oD6fI=",
    skipStep: "/fJ44XbZw7mNvwX7BQSY3kR0jV_FWoge2qBE0GQFzJ3Q=",
    updateTheme: "/Hg-aBPypnFrtKXj3_10nEoR8XhEAe0JIFc3usIwrfJA=",
    discardGameJam: "/fOnnlK9JiIrabH9ZoqSPqKRyn_TZL3-x3pbvPBmy9A8=",
    getGames: "/8ZE0edXGMqVR4VMQkbTDZ5J4DgjfkzJrirGh8ZmwJsc=",
    confirmGameJam: "/AXpUu27nmhHs_rVyt3YmxVuPFy5l7xeXl02SSvlukrE=",
    createGameJam: "/Re0skVgk3Th4p8ngjUDCnANpCfZ_w-uIHz_NwXbaTGA=",
    editReadyGameJam: "/4e-p09S80ub6sHzY4hGUYQ69ExGqwkJgoCugVg6TqZw=",
    discardReadyGameJam: "/kSboHe9ooJwR2S7mSWTMOT1v5U-IO3_o4h7nXGhs5Bc=",
  },
  getDashboardData: "/Gbp6JL1revpDThKthMbadF9KXr0ikNF2Fs2am9SnFRw=",
  applyCouponCode: "/88pQV0mlK408Mv9nEqWfxe4YGVLfemmHKGcm_ihnrWE=",
  continueWithoutPayment: "/c76LL3IRlp0TefuTJqKnf-rJZQsf5sKf-9UQvCAS6Yw=",
  getPurchaseHistory: "/WPGiUHqqD-U0bn951MjNyHnEPmnLS03_1Bdp7U1lip4=",
  downloadInvoice: "/xQ7QpqTWQBBspzq_tq7FQEY6XJESg-2z9pvXl1hzrls=",
  startGameJam: "/yuOgBIr6HRvA1zBfAKHuE5whlhc9uBpMH4JAesWQmPA=",
  getDashboardMenuNumbers: "/ExnvOTrHfcTiLVKgOC3qiJ4qcN8htmmkCG_9PGYQo8M=",
  downloadGameJamReport: "/aHLtISTb1yeXV3QXe24RdQdoPsc1TMRRDTREfaL6_pU=",
  submitContactForm: "/YgMzfK6loR8UUc20mIegTrpUa_2xnJnMLFqoYVSx350=",
  downloadQRCode: "/T5z8vOZ1Jnj2VxTwHUA74L5IAbMhXy5Fn0LhtEgx6JA=",
};
