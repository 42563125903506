const TokenService = {
  getToken(ID_TOKEN) {
    return window.localStorage.getItem(ID_TOKEN);
  },

  saveToken(ID_TOKEN, token) {
    window.localStorage.setItem(ID_TOKEN, token);
  },

  destroyToken(ID_TOKEN) {
    window.localStorage.removeItem(ID_TOKEN);
  },
};

export default TokenService;
